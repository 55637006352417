<template>
  <div class="view view--references">
    <div class="view-header" v-if="title">
      <div class="view-header__header">
        <Pagination />
      </div>
      <div class="view-header__footer">
        <Btn class="back-btn" @click="goBack()" round grow icon="arrow-left" />
        <h1 class="page-title" v-html="title"></h1>
      </div>
    </div>
    <div class="view-header" v-else-if="pageTitle">
      <div class="view-header__header">
        <Pagination />
      </div>
      <div class="view-header__footer">
        <Btn class="back-btn" @click="goBack()" round grow icon="arrow-left" />
        <h1 class="page-title">{{pageTitle}}</h1>
      </div>
    </div>

    <div class="view-body">
      <Section class="section section--list">
        <List
          :api="list.api"
          :header="list.headers"
          :items="list.col"
          :item-key="list.key"
          :disable-checkbox="true"
        >
          <template v-slot:date="{ item }">
            {{ new Date(item.date).toLocaleString() }}
          </template>

          <template v-slot:type="{ item }">
            {{ item.type.uid }}
          </template>

          <template v-slot:actions="{ item }">
            <Btn
              icon="visibility"
              default
              :to="{
                name: 'ressourceLog',
                params: {
                  ressource: ressource,
                  ressourceid: ressource_id,
                  logid: item.id,
                  title: title,
                  from: from,
                },
              }"
              round
              grow
            />
          </template>
        </List>
      </Section>
    </div>
  </div>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import List from '@/components/list/List.vue'
import Btn from '@/components/base/Btn.vue'
import Pagination from '@/components/list/Pagination.vue'

export default {
  name: 'LogsView',

  components: {
    Pagination,
    Btn,
    List,
    Section,
  },

  props: {
  },

  data() {
    return {
      filters: this.$route.params.filters,
      ressource: null,
      ressource_id: null,
      title: null,
      from: null,
      from_ressource_id: null,
      list: {
        api: {
          endpoint: 'log',
          params: {
            sort: 'date.DESC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Date', dataField: 'date' },
          { label: 'Types' },
          { label: 'Objets' },
        ],
        col: ['date', 'type', 'objet'],
        key: 'id',
      },
    }
  },
  created() {
    this.from_ressource_id = this.$route.params.fromRessourceId
    this.ressource_id = this.$route.params.ressourceid
    this.ressource = this.$route.params.ressource
    this.list.api.params.filters = this.filters || `ressource_id:eq(${this.ressource_id})`
    this.title = this.$route.params.title || `Logs ${this.$route.params.ressource}`

    if (this.$route.params.from !== undefined) {
      this.from = this.$route.params.from
    }
  },
  methods: {
    goBack() {
      if (this.from !== null && this.from_ressource_id !== null) {
        this.$router.push({ name: this.from, params: { id: this.from_ressource_id } })
      } else if (this.from !== null && this.ressource_id !== null) {
        this.$router.push({ name: this.from, params: { id: this.ressource_id } })
      } else if (this.from !== null) {
        this.$router.push({ name: this.from })
      } else {
        this.$router.back()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
